export const FloatingDiv = ({ openArrow, children }) => {
  return (
    <div
      styles={{
        transition: "all 0.2s ease-in-out",
        PointerEvent: `${openArrow ? "default" : "none"}`,
      }}
      className={`relative lg:absolute rounded-lg flex w-full lg:w-max flex-col bg-primaryWhite top-0 lg:top-[110%] left-4 lg:left-auto text-[15px] lg:text-auto lg:right-[116px] ${
        openArrow ? "opacity-100" : "opacity-0"
      }`}
    >
      {children}
    </div>
  );
};

export default FloatingDiv
