const FeatureDrawerWrapper = ({ active, children }) => {
  return (
    <div
      className={`w-full h-fit flex flex-col lg:hidden px-4 py-4 bg-white rounded-t-lg pt-8 transition-all duration-500 ease-in-out fixed z-[101] ${
        active
          ? "bottom-0 opacity-100"
          : "-bottom-full opacity-0 animate-disappear lg:animate-none"
      }`}
    >
      {children}
    </div>
  );
};

export default FeatureDrawerWrapper;
