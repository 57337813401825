import { useState, useEffect } from "react";
import NavLink from "./NavLink";
import FloatingDiv from "./components/HamburgerMenuComponents/FloatingDiv";
import { useRouter } from "next/router";
import { Skeleton } from "@chakra-ui/react";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import useCustomToast from "../../utils/useCustomToast";
import { useDrawerContext } from "../../../context/DrawerContext/DrawerContext";
import Nav from "./components/NavbarComponents/Nav";
import NavLogoWrapper from "./components/NavbarComponents/NavLogoWrapper";
import WrapperHamburger from "./components/HamburgerMenuComponents/WrapperHamburger";
import HamburgerIcon from "./components/HamburgerMenuComponents/HamburgerIcon";
import NavLinkWrapper from "./components/NavbarComponents/NavLinkWrapper";
import ProfileDropDown from "./components/HamburgerMenuComponents/ProfileDropDown";
import StyledLink from "./components/NavbarComponents/StyledLink";
import { useBackdropContext } from "../../../context/BackdropContext/BackdropContext";
import { useStateManagementContext } from "../../../context/StateManagementContext/StateManagementContext";
import { useMixpanelClient } from "../../../hooks/useMixpanelClient";

const Navbar = () => {
  const { setDrawerOpened, setUrls } = useDrawerContext();
  const { setState } = useStateManagementContext();
  const { setBackdropActive } = useBackdropContext();
  const [inAuthPage, setInAuthPage] = useState(false);
  const [in404Page, setIn404Page] = useState(false);
  const { user, logout, loggingIn } = useAuthContext();
  const [openArrow, setOpenArrow] = useState(false);
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const toast = useCustomToast();

  useEffect(() => {
    if (
      router.pathname.includes("/login") ||
      router.pathname.includes("/register")
    ) {
      setInAuthPage(true);
    } else {
      setInAuthPage(false);
    }

    if (router.pathname.includes("/404")) {
      setIn404Page(true);
    } else {
      setIn404Page(false);
    }
  }, [router.pathname]);

  const toggleHamburger = () => {
    setOpen(!open);
  };

  const loginHandler = () => {
    setOpen(false);
    setBackdropActive(false);
    setDrawerOpened(false);
    if (navigator.cookieEnabled) {
      router.push({
        pathname: "/login",
        query: { redirectUrl: router.asPath },
      });
    } else {
      toast({
        type: "warning",
        title: "Cookies Disabled",
        message: "Please turn on your cookies in order to login.",
      });
    }
  };

  const getFirstname = () => {
    const name = user?.fullName.split(" ")[0];
    return name[0].toUpperCase(0) + name.substring(1);
  };

  const onLogout = async () => {
    useMixpanelClient.track("sign_out")
    await logout();
    setUrls([]);
    setOpen(false);
    setOpenArrow(false);
    setDrawerOpened(false);
    setState("landing");

    toast({
      type: "success",
      title: "Logout successful!",
      message: `Until we meet again!`,
    });
    router.replace("/");
  };
  return (
    !in404Page && (
      <Nav open={open} openArrow={openArrow}>
        <NavLogoWrapper>
          <NavLink href="/" closeNav={open && toggleHamburger}>
            <div className="flex justify-start items-center">
              <h1 className="text-2xl font-bold text-black inline-block mt-1">
                RISTEK
              </h1>
              <h1 className="text-2xl font-bold inline-block text-[#5038BC] mt-1">
                .LINK
              </h1>
            </div>
          </NavLink>

          <WrapperHamburger open={open} onClick={toggleHamburger}>
            <HamburgerIcon open={open} />
          </WrapperHamburger>
        </NavLogoWrapper>

        <NavLinkWrapper open={open}>
          {(user || !inAuthPage) && (
            <div className="flex flex-col items-start w-full lg:w-fit lg:flex-row lg:gap-8 lg:mr-8 lg:items-center h-auto lg:h-full ">
              <StyledLink
                onClick={() => {
                  setOpen(false);
                  setDrawerOpened(false);
                  setBackdropActive(false);
                  useMixpanelClient.track("open_shorten_url", {
                    "is_logged_in": user ? "True" : "False"
                  });
                  router.push("/");
                }}
                style={{ cursor: "pointer", width: "fit-content" }}
              >
                Shorten a URL
              </StyledLink>
              <StyledLink
                onClick={() => {
                  setOpen(false);
                  setDrawerOpened(false);
                  setBackdropActive(false);
                  router.push("/bulk-shortener");
                }}
                style={{ cursor: "pointer", width: "fit-content" }}
              >
                Shorten in Bulk
              </StyledLink>
            </div>
          )}
          <>
            {loggingIn ? (
              <Skeleton height="30px" width="100px" borderRadius="10px" />
            ) : user ? (
              <>
                <ProfileDropDown
                  openArrow={openArrow}
                  onClick={() => setOpenArrow(!openArrow)}
                >
                  <p className="text-black inline-flex font-[500]">
                    Welcome, {getFirstname()}
                    <img
                      src="/images/icons/arrow_drop_down.svg"
                      alt="arrow"
                      className={`xlg:ml-3 inline ${
                        openArrow ? "rotate-180" : "rotate-0"
                      }`}
                      style={{ transition: "all .2s ease-in" }}
                    />
                  </p>
                </ProfileDropDown>

                <FloatingDiv openArrow={openArrow}>
                  <NavLink closeNav={toggleHamburger} href="/">
                    <span
                      onClick={onLogout}
                      className="text-stateError block lg:border-t border-t-0 py-4 px-8 lg:text-base"
                    >
                      Sign Out
                    </span>
                  </NavLink>
                </FloatingDiv>
              </>
            ) : (
              <StyledLink
                onClick={loginHandler}
                style={{ cursor: "pointer", width: "fit-content" }}
              >
                Sign in
              </StyledLink>
            )}
          </>
        </NavLinkWrapper>
      </Nav>
    )
  );
};

export default Navbar;
