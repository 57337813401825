const ProfileDropDown = ({ onClick, children }) => {
  return (
    <div
      onClick={onClick}
      className={`w-full lg:w-max flex py-3 lg:py-0 pl-0 lg:pl-0 lg:text-base text-md cursor-pointer text-[#828282] `}
    >
      {children}
    </div>
  );
};

export default ProfileDropDown;
