const NavLinkWrapper = ({ open, children }) => {
  return (
    <div
      style={{
        fontSize: "clamp(12px, 4vw, 18px)",
      }}
      className={`flex flex-col lg:flex-row lg:justify-start lg:justify-end lg:items-center absolute lg:static  w-[100vw] lg:w-auto h-[100vh] lg:h-full bg-white py-[1.8rem] px-6 top-[3.5rem] box-border items-start lg:!p-0 left-[100vw] transition-all duration-500 ease-in-out lg:transition-none ${
        open ? "opacity-100 !left-0" : "opacity-0 left-[100vw] lg:opacity-100"
      }`}
    >
      {children}
    </div>
  );
};

export default NavLinkWrapper;
