import Footer from "./Footer/Footer";
import Navbar from "./Navbar/Navbar";
import { useBackdropContext } from "../../context/BackdropContext/BackdropContext";
import Backdrop from "../common/Backdrop/Backdrop";
import FeatureDrawer from "../common/FeatureDrawer/FeatureDrawer";
import { useDrawerContext } from "../../context/DrawerContext/DrawerContext";

const PageWrapper = ({ children }) => {
  return (
    <div className="bg-primaryAlabaster min-h-screen h-full flex flex-col justify-center items-center w-full">
      {children}
    </div>
  );
};

const Layout = ({ children }) => {
  const { backdropActive } = useBackdropContext();
  const { featureDrawerOpened } = useDrawerContext();
  return (
    <div className="bg-primaryAlabaster">
      <Navbar />
      <>
        <PageWrapper>{children}</PageWrapper>
        <Footer />
        <Backdrop active={backdropActive} />
        <FeatureDrawer active={featureDrawerOpened} />
      </>
    </div>
  );
};

export default Layout;
