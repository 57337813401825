import { useRouter } from "next/router";

import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { useBackdropContext } from "../../../context/BackdropContext/BackdropContext";
import { useDrawerContext } from "../../../context/DrawerContext/DrawerContext";
import { useStateManagementContext } from "../../../context/StateManagementContext/StateManagementContext";
import { AnalyticLogo, MyQRCodeIcon } from "../URLDrawer/Logos";
import ExitFeatureDrawer from "./components/FeatureCardComponents/ExitFeatureDrawer";
import FeatureCard from "./components/FeatureCardComponents/FeatureCard";
import FeatureHeaderWrapper from "./components/FeatureCardComponents/FeatureHeaderWrapper";
import FeatureList from "./components/FeatureCardComponents/FeatureList";
import FeatureListWrapper from "./components/FeatureCardComponents/FeatureListWrapper";
import FeatureDrawerWrapper from "./components/FeatureDrawerWrapper";

const FeatureDrawer = ({ active }) => {
  const { user } = useAuthContext();
  const { setBackdropActive } = useBackdropContext();
  const { setFeatureDrawerOpened, setDrawerOpened } = useDrawerContext();
  const { setState } = useStateManagementContext();
  const router = useRouter();

  return (
    <>
      {
        <FeatureDrawerWrapper active={active}>
          <ExitFeatureDrawer
            onClick={() => {
              setBackdropActive(false);
              setFeatureDrawerOpened(false);
            }}
          >
            &times;
          </ExitFeatureDrawer>
          <FeatureCard>
            <FeatureHeaderWrapper>
              <h1>Other Feature</h1>
            </FeatureHeaderWrapper>
            <FeatureListWrapper>
              <FeatureList
                onClick={() => {
                  router.push("/");
                  setState("generate-qr");
                  setDrawerOpened(false);
                  setBackdropActive(false);
                  setFeatureDrawerOpened(false);
                }}
              >
                <MyQRCodeIcon
                  color={user ? "#5038BC" : "rgb(156, 163, 175)"}
                  size={"clamp(16px, 2vw, 20px)"}
                />
                <h1>My QR Code</h1>
              </FeatureList>
              <FeatureList
                onClick={() => {
                  router.push("/");
                  setState("analytic");
                  setDrawerOpened(false);
                  setBackdropActive(false);
                  setFeatureDrawerOpened(false);
                }}
              >
                <AnalyticLogo
                  color={user ? "#5038BC" : "rgb(156, 163, 175)"}
                  size={"clamp(16px, 2vw, 20px)"}
                />
                <h1>Analytic</h1>
              </FeatureList>
            </FeatureListWrapper>
          </FeatureCard>
        </FeatureDrawerWrapper>
      }
    </>
  );
};

export default FeatureDrawer;
