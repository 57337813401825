import StyledIconWrapper from "./components/StyledIconWrapper";

const SocialContainer = () => {
  return (
    <StyledIconWrapper>
      {ListIcon.map((item, id) => (
        <a target="_blank" rel="noreferrer" key={id} href={item.url}>
          <img
            className="object-contain	w-6"
            src={`/images/icons/${item.name}.svg`}
            alt={item.name}
          />
        </a>
      ))}
    </StyledIconWrapper>
  );
};

export default SocialContainer;

const ListIcon = [
  {
    name: 'line',
    url: 'https://liff.line.me/1645278921-kWRPP32q?accountId=rye2953f&openerPlatform=webview&openerKey=webview%3AunifiedSearch',
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/ristek.csui/',
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/RistekCSUI',
  },
  {
    name: 'facebook',
    url: 'https://www.facebook.com/RistekCSUI',
  },
  {
    name: 'medium',
    url: 'https://medium.com/ristek-fasilkom',
  },
  {
    name: 'linkedin',
    url: 'https://www.linkedin.com/company/ristek-fakultas-ilmu-komputer-universitas-indonesia',
  },
];
