import "../styles/globals.css";
import "@fontsource/poppins";
import { DrawerContextProvider } from "../context/DrawerContext/DrawerContext";
import { ChakraProvider } from "@chakra-ui/react";
import { chakraTheme } from "../styles/theme";
import { AuthContextProvider } from "../context/AuthContext/AuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Layout from "../components/layout/Layout";
import { BackdropContextProvider } from "../context/BackdropContext/BackdropContext";
import { StateManagementContextProvider } from "../context/StateManagementContext/StateManagementContext";
import { AdsProvider } from "@ristek-kit/ads";

import "@ristek-kit/ads/styles.css";

function MyApp({ Component, pageProps }) {
  return (
    <AuthContextProvider>
      <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
        <ChakraProvider theme={chakraTheme}>
          <StateManagementContextProvider>
            <BackdropContextProvider>
              <DrawerContextProvider>
                <AdsProvider theme="light" platform="RistekLink">
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </AdsProvider>
              </DrawerContextProvider>
            </BackdropContextProvider>
          </StateManagementContextProvider>
        </ChakraProvider>
      </GoogleOAuthProvider>
    </AuthContextProvider>
  );
}

export default MyApp;
