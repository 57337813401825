import { createContext, useState, useEffect, useContext } from "react";
import jwt from "jwt-decode";
import axios from "axios";
import useCustomToast from "../../components/utils/useCustomToast";

export const AuthStatus = {
  AUTHENTICATED: 1,
  NOT_AUTHENTICATED: 2,
  SESSION_EXPIRED: 3,
  TOKEN_UNVERIFIED: 4,
};

export const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loggingIn, setLoggingIn] = useState(true);
  const [authStatus, setAuthStatus] = useState();
  const [isNotified, setIsNotified] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    if (
      (authStatus === AuthStatus.SESSION_EXPIRED ||
        authStatus === AuthStatus.TOKEN_UNVERIFIED) &&
      !isNotified
    ) {
      toast({
        type: "error",
        title: "Session Expired",
        message: "Your session has expired, please login again.",
      });
      setIsNotified(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus]);

  // when page is first loaded
  useEffect(() => {
    setLoggingIn(true);
    async function initAuth() {
      await authenticate();
      setLoggingIn(false);
    }
    initAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this is for when user just logged in
  const login = (data) => {
    const userData = { fullName: data.fullName, email: data.email };
    setUser(userData);
    setAuthStatus(AuthStatus.AUTHENTICATED);
    try {
      localStorage.setItem("user", JSON.stringify(userData));
    } catch {}
  };

  const logout = async () => {
    // clear UserAuthToken cookie
    await axios.post("/api/logout");
    const hasOpen = localStorage.getItem("hasOpen");
    const lastBulk = localStorage.getItem("lastBulk");
    localStorage.clear();
    if (hasOpen) localStorage.setItem("hasOpen", hasOpen);
    if (lastBulk) localStorage.setItem("lastBulk", lastBulk);
    setUser(null);
    setAuthStatus(AuthStatus.NOT_AUTHENTICATED);
  };

  // checks auth status
  const authenticate = async () => {
    const result = await axios.get("/api/auth");
    const tokenStatus = result.data.message;
    if (tokenStatus === "TOKEN_UNVERIFIED") {
      await logout();
      setAuthStatus(AuthStatus.TOKEN_UNVERIFIED);
      return AuthStatus.TOKEN_UNVERIFIED;
    } else if (tokenStatus === "TOKEN_DOES_NOT_EXIST") {
      let userFromStorage;
      try {
        userFromStorage = localStorage.getItem("user");
      } catch {
        userFromStorage = null;
      }
      if (user || userFromStorage) {
        await logout();
        setAuthStatus(AuthStatus.SESSION_EXPIRED);
        return AuthStatus.SESSION_EXPIRED;
      }
      setAuthStatus(AuthStatus.NOT_AUTHENTICATED);
      return AuthStatus.NOT_AUTHENTICATED;
    } else {
      if (!user) {
        const data = jwt(tokenStatus);
        const userData = { email: data.email, fullName: data.fullName };
        try {
          localStorage.setItem("user", userData);
        } catch {}
        setUser(userData);
      }
      setAuthStatus(AuthStatus.AUTHENTICATED);
      return AuthStatus.AUTHENTICATED;
    }
  };

  const value = {
    user,
    loggingIn,
    authStatus,
    login,
    logout,
    authenticate,
  };
  return (
    <AuthContext.Provider value={value}>
      {loggingIn ? null : children}
    </AuthContext.Provider>
  );
};
