import { useBackdropContext } from "../../../context/BackdropContext/BackdropContext";
import { useDrawerContext } from "../../../context/DrawerContext/DrawerContext";
import useWindowSize from "../../../hooks/useWindowSize";

const Backdrop = ({ active }) => {
  const { setFeatureDrawerOpened } = useDrawerContext();
  const { setBackdropActive } = useBackdropContext();
  const { width } = useWindowSize();

  const closeBackdropHandler = () => {
    if (width < 1024) {
      setBackdropActive(false);
      setFeatureDrawerOpened(false);
    }
  };
  return (
    <div
      onClick={closeBackdropHandler}
      className={`${
        active
          ? "z-[100] w-full lg:z-[88] bg-[#8B8B8B55] fixed top-0 left-0 w-screen h-full"
          : "hidden"
      }`}
    ></div>
  );
};

export default Backdrop;
