const Nav = ({ open, openArrow, children }) => {
  return (
    <nav
      style={{ filter: "drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15))" }}
      className={`text-base h-14 lg:h-20 fixed -top-0 w-full bg-primaryWhite flex py-[0.7rem] lg:py-5 px-[24px] lg:px-20 justify-between items-center ${
        open || openArrow ? "lg:z-[999]" : "lg:z-[89]"
      } ${open ? "z-[90]" : "z-[89]"}`}
    >
      {children}
    </nav>
  );
};

export default Nav;
