import { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import { useAuthContext } from "../AuthContext/AuthContext";

export const DrawerContext = createContext();

export const useDrawerContext = () => useContext(DrawerContext);

export const DrawerContextProvider = ({ children }) => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [urls, setUrls] = useState([]);
  const [loadingUrls, setLoadingUrls] = useState(false);
  const [featureDrawerOpened, setFeatureDrawerOpened] = useState(false);
  const { user } = useAuthContext();

  const value = {
    drawerOpened,
    setDrawerOpened,
    urls,
    setUrls,
    loadingUrls,
    featureDrawerOpened,
    setFeatureDrawerOpened,
  };

  value.refreshUrls = async () => {
    setLoadingUrls(true);
    const urls = await axios
      .get("/api/urls")
      .then((res) => res.data.message.urls);
    if (urls) {
      urls.sort(
        (a, b) =>
          b.createdDate._seconds * 1000 +
          b.createdDate._nanoseconds / 1000000 -
          (a.createdDate._seconds * 1000 + a.createdDate._nanoseconds / 1000000)
      );
      setUrls(urls);
    }
    setLoadingUrls(false);
  };

  useEffect(() => {
    if (user) {
      async function initUserData() {
        await value.refreshUrls();
      }
      initUserData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};
