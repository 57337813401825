import { extendTheme } from "@chakra-ui/react";

export const theme = {
  colors: {
    primaryWhite: "#FFFFFF",
    primaryMalibu: "#64E6FB",
    primaryPurple: "#5038BC",
    primaryAlabaster: "#F8F8F8",
    secondMineShaft: "#333333",
    secondCerise: "#C424A3",
    secondGolden: "#FFD668",
    stateSuccess: "#27AE60",
    stateWarn: "#F7B500",
    stateError: "#EB5757",
  },
  fontSizes: {
    m_small: "12px",
    small: "14px",
    m_regular: "14px",
    regular: "18px",
    m_subHeader: "18px",
    subHeader: "24px",
    m_secHeader: "24px",
    secHeader: "2rem",
    m_mainHeader: "3rem",
    mainHeader: "64px",
  },
};

export const chakraTheme = extendTheme({
  fonts: {
    body: `'Poppins', sans-serif`,
  },
  colors: {
    brand: {
      500: "#5028BC",
    },
  },
});
