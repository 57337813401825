import { createContext, useState, useContext } from "react";

export const BackdropContext = createContext();

export const useBackdropContext = () => useContext(BackdropContext);

export const BackdropContextProvider = ({ children }) => {
  const [backdropActive, setBackdropActive] = useState(false);
  const value = { backdropActive, setBackdropActive };

  return (
    <BackdropContext.Provider value={value}>
      {children}
    </BackdropContext.Provider>
  );
};
