import { useToast } from "@chakra-ui/react";

const useCustomToast = () => {
  const toast = useToast();

  const customToast = ({ type, title, message, position }) => {
    return toast({
      containerStyle: { width: "90vw", fontSize: "14px" },
      title: title || "",
      description: message || "",
      status: type,
      duration: 4000,
      isClosable: true,
      position: position || "top",
    });
  };

  return customToast;

}

export default useCustomToast