import { forwardRef } from "react";

const StyledLink = forwardRef(({ active, onClick, LogoNav, children }, ref) => {
  return (
    <div
      onClick={onClick}
      className={`lg:text-base hover:text-primaryPurple-active cursor-pointer ${
        active ? "text-[#50338bc] font-semibold" : "text-black font-medium"
      } ${
        LogoNav ? "lg:!py-0 lg:!px-0" : "w-full lg:w-auto"
      } py-[0.75rem] px-0 text-left lg:!py-0 lg:!px-0`}
    >
      {children}
    </div>
  );
});

StyledLink.displayName = "StyledLink";

export default StyledLink;
