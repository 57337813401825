const FeatureList = ({ onClick, children }) => {
  return (
    <div
      onClick={onClick}
      className="pl-6 text-[1em] flex font-black text-[#5038BC] gap-2 items-center cursor-pointer"
    >
      {children}
    </div>
  );
};

export default FeatureList;
