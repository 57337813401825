export const CancelLogo = (props) => {
  return (
    <svg
      width={`${props.size}`}
      height={`${props.size}`}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66683 1.27337L8.72683 0.333374L5.00016 4.06004L1.2735 0.333374L0.333496 1.27337L4.06016 5.00004L0.333496 8.72671L1.2735 9.66671L5.00016 5.94004L8.72683 9.66671L9.66683 8.72671L5.94016 5.00004L9.66683 1.27337Z"
        fill="#EB5757"
      />
    </svg>
  );
};
export const SaveLogo = (props) => {
  return (
    <svg
      width={`${props.size}`}
      height={`${props.size}`}
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.0001 7.78002L1.2201 5.00002L0.273438 5.94002L4.0001 9.66669L12.0001 1.66668L11.0601 0.726685L4.0001 7.78002Z"
        fill="#5038BC"
      />
    </svg>
  );
};

export const EditLogo = (props) => {
  return (
    <svg
      width={`${props.size}`}
      height={`${props.size}`}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 8.23003V9.75003C0.5 9.89003 0.61 10 0.75 10H2.27C2.335 10 2.4 9.97503 2.445 9.92503L7.905 4.47003L6.03 2.59503L0.575 8.05003C0.525 8.10003 0.5 8.16003 0.5 8.23003ZM9.355 3.02003C9.55 2.82503 9.55 2.51003 9.355 2.31503L8.185 1.14503C7.99 0.950029 7.675 0.950029 7.48 1.14503L6.565 2.06003L8.44 3.93503L9.355 3.02003Z"
        fill={props.color}
      />
    </svg>
  );
};

export const LockedLogo = (props) => {
  return (
    <svg
      width={`${props.size}`}
      height={`${props.size}`}
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="self-start"
    >
      <path
        d="M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM8 16C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16ZM11.1 7H4.9V5C4.9 3.29 6.29 1.9 8 1.9C9.71 1.9 11.1 3.29 11.1 5V7Z"
        fill="#C424A3"
      />
    </svg>
  );
};

export const MyURLLogo = () => (
  <svg
    width="60"
    height="41"
    viewBox="0 0 60 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_179_7223)">
      <circle
        cx="19.8846"
        cy="20.3262"
        r="16"
        transform="rotate(90.1659 19.8846 20.3262)"
        stroke="#FFD668"
        strokeWidth="8"
      />
    </g>
    <circle
      cx="39.8846"
      cy="20.3262"
      r="16"
      transform="rotate(90.1659 39.8846 20.3262)"
      stroke="#C424A3"
      strokeWidth="8"
    />
    <defs>
      <clipPath id="clip0_179_7223">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(40.1157 0.557983) rotate(90.1659)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const SearchIcon = (props) => {
  return (
    <svg
      onClick={props.onClick}
      width={`${props.size}`}
      height={`${props.size}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute top-0 right-0 translate-y-1/2 -translate-x-full"
    >
      <path
        d="M19.3613 18.2168L14.6012 13.2662C15.8251 11.8113 16.4957 9.98069 16.4957 8.07499C16.4957 3.62251 12.8732 0 8.4207 0C3.96821 0 0.345703 3.62251 0.345703 8.07499C0.345703 12.5275 3.96821 16.15 8.4207 16.15C10.0922 16.15 11.6851 15.6458 13.047 14.6888L17.8432 19.677C18.0436 19.8852 18.3133 20 18.6022 20C18.8757 20 19.1352 19.8957 19.3321 19.7061C19.7506 19.3034 19.764 18.6357 19.3613 18.2168ZM8.4207 2.10652C11.7118 2.10652 14.3892 4.78391 14.3892 8.07499C14.3892 11.3661 11.7118 14.0435 8.4207 14.0435C5.12961 14.0435 2.45222 11.3661 2.45222 8.07499C2.45222 4.78391 5.12961 2.10652 8.4207 2.10652Z"
        fill="#5038BC"
      />
    </svg>
  );
};

export const CopyLogo = (props) => {
  return (
    <svg
      cursor="pointer"
      onClick={props.onClick}
      width="clamp(12px, 2vw, 19px)"
      height="clamp(14px, 2vw, 22px)"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: "clamp(8px, 1vw, 16px)" }}
    >
      <path
        d="M13 0H2C0.9 0 0 0.9 0 2V15C0 15.55 0.45 16 1 16C1.55 16 2 15.55 2 15V3C2 2.45 2.45 2 3 2H13C13.55 2 14 1.55 14 1C14 0.45 13.55 0 13 0ZM17 4H6C4.9 4 4 4.9 4 6V20C4 21.1 4.9 22 6 22H17C18.1 22 19 21.1 19 20V6C19 4.9 18.1 4 17 4ZM16 20H7C6.45 20 6 19.55 6 19V7C6 6.45 6.45 6 7 6H16C16.55 6 17 6.45 17 7V19C17 19.55 16.55 20 16 20Z"
        fill="#5038BC"
      />
    </svg>
  );
};

export const MyQRCodeIcon = (props) => {
  return (
    <svg
      width={`${props.size}`}
      height={`${props.size}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 8V10M8 2V4M12 8V10M8 12H14M12 14H14M4 8H6M4 4.00733L4.00667 4M8 8.00733L8.00667 8M2 8.00733L2.00667 8M8 6.00733L8.00667 6M8 10.0073L8.00667 10M10 14.0073L10.0067 14M8 14.0073L8.00667 14M14 8.00733L14.0067 8M14 10.0073L14.0067 10M12 4.00733L12.0067 4M4 12.0073L4.00667 12M6 2.4V5.6C6 5.70609 5.95786 5.80783 5.88284 5.88284C5.80783 5.95786 5.70609 6 5.6 6H2.4C2.29391 6 2.19217 5.95786 2.11716 5.88284C2.04214 5.80783 2 5.70609 2 5.6V2.4C2 2.29391 2.04214 2.19217 2.11716 2.11716C2.19217 2.04214 2.29391 2 2.4 2H5.6C5.70609 2 5.80783 2.04214 5.88284 2.11716C5.95786 2.19217 6 2.29391 6 2.4ZM14 2.4V5.6C14 5.70609 13.9579 5.80783 13.8828 5.88284C13.8078 5.95786 13.7061 6 13.6 6H10.4C10.2939 6 10.1922 5.95786 10.1172 5.88284C10.0421 5.80783 10 5.70609 10 5.6V2.4C10 2.29391 10.0421 2.19217 10.1172 2.11716C10.1922 2.04214 10.2939 2 10.4 2H13.6C13.7061 2 13.8078 2.04214 13.8828 2.11716C13.9579 2.19217 14 2.29391 14 2.4ZM6 10.4V13.6C6 13.7061 5.95786 13.8078 5.88284 13.8828C5.80783 13.9579 5.70609 14 5.6 14H2.4C2.29391 14 2.19217 13.9579 2.11716 13.8828C2.04214 13.8078 2 13.7061 2 13.6V10.4C2 10.2939 2.04214 10.1922 2.11716 10.1172C2.19217 10.0421 2.29391 10 2.4 10H5.6C5.70609 10 5.80783 10.0421 5.88284 10.1172C5.95786 10.1922 6 10.2939 6 10.4Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AnalyticLogo = ({ color, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.06668 3.33325H8.93334V12.6666H7.06668V3.33325ZM3.33334 6.13325H5.33334V12.6666H3.33334V6.13325ZM12.6667 8.66659H10.8V12.6666H12.6667V8.66659Z"
        fill={color}
      />
    </svg>
  );
};

export const OtherFeatureIcon = ({ color, size }) => {
  return (
    <svg
      width="4"
      height={size}
      viewBox="0 0 4 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="12" r="2" transform="rotate(-90 2 12)" fill={color} />
      <circle
        cx="2"
        cy="12"
        r="1.5"
        transform="rotate(-90 2 12)"
        stroke={color}
        strokeOpacity="0.3"
      />
      <circle cx="2" cy="7" r="2" transform="rotate(-90 2 7)" fill={color} />
      <circle
        cx="2"
        cy="7"
        r="1.5"
        transform="rotate(-90 2 7)"
        stroke={color}
        strokeOpacity="0.3"
      />
      <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill={color} />
      <circle
        cx="2"
        cy="2"
        r="1.5"
        transform="rotate(-90 2 2)"
        stroke={color}
        strokeOpacity="0.3"
      />
    </svg>
  );
};
