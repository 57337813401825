import SocialContainer from "./SocialContainer";
import FlexDiv from "./components/FlexDiv";
import StyledFooter from "./components/StyledFooter";

const Footer = () => {
  return (
    <StyledFooter>
      <FlexDiv>
        <img
          className="h-11 object-contain mx-auto lg:mx-0 mb-8 max-w-[248px]"
          src="/images/FooterBrand2023.svg"
          alt=""
        />

        <div className="w-full lg:mb-16 max-w-md mx-auto lg:mx-10 text-center lg:text-left mb-8 ">
          <p className="justify-center	lg:justify-start text-sm mb-4 text-secondMineShaft flex items-center font-semibold">
            <img
              src="/images/MapPointer.svg" // Route of the image file
              alt="MapPoint"
              className="mr-3 object-contain"
            />
            Universitas Indonesia, Depok
          </p>
          <p className="text-sm text-secondMineShaft">
          RISTEK is a student-founded non-profit organization from Universitas Indonesia&apos;s Faculty of Computer Science, supported by a United States of America 501(c)(3) non-profit.
          </p>

          <p className="justify-center mt-4	lg:justify-start text-sm text-secondMineShaft flex items-center font-semibold">
            Contact us (Email):
          </p>
          <p className="text-sm text-secondMineShaft">
            <a href="mailto: help@ristek.cs.ui.ac.id">
              help@ristek.cs.ui.ac.id
            </a>
          </p>
        </div>

        <div className="w-full lg:mb-16 max-w-sm lg:mx-0 mx-auto lg:text-left mb-8">
          <span className="inline-flex lg:justify-items-start justify-items-center">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.gojek.com/en-id/"
            >
              <p className="justify-center lg:justify-start text-xs lg:text-sm mb-4 text-secondMineShaft flex items-center font-semibold">
                Official Learning Partner
              </p>
              <div className="flex">
                <img
                  className="object-contain w-20 mr-4 lg:mr-5 lg:w-24"
                  src="/images/gojek.svg"
                  alt=""
                />
                <img
                  className="object-contain w-20 mr-4 lg:mr-5 lg:w-16"
                  src="/images/Goto.png"
                  alt=""
                />
              </div>
            </a>
            <a
              className="border-l pl-4 ml-4"
              target="_blank"
              rel="noreferrer"
              href="https://hackplus.io/"
            >
              <p className="justify-center lg:justify-start text-xs lg:text-sm mb-4 text-secondMineShaft flex items-center font-semibold">
                Accelerated by
              </p>
              <img
                className="object-contain w-28 lg:w-30"
                src="/images/hack.svg"
                alt=""
              />
            </a>
          </span>
        </div>
      </FlexDiv>

      <div className="w-full items-center flex justify-between align-start lg:flex-row flex-col-reverse">
        <p className="mt-6 lg:mt-0 text-sm text-secondMineShaft">
          © {new Date().getFullYear()} RISTEK Fasilkom
        </p>
        <SocialContainer />
      </div>
    </StyledFooter>
  );
};

export default Footer;
