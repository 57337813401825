import Link from 'next/link';
import { useRouter } from 'next/router';
import StyledLink from './components/NavbarComponents/StyledLink';

const NavLink = ({ children, href, closeNav, LogoNav }) => {
  const router = useRouter();

  return (
    <Link href={href} passHref>
      <StyledLink
        LogoNav={LogoNav}
        onClick={closeNav}
        active={router.asPath == href}
      >
        {children}
      </StyledLink>
    </Link>
  );
};

export default NavLink;
