import { createContext, useState, useContext} from "react";

export const StateManagementContext = createContext();

export const useStateManagementContext = () =>
  useContext(StateManagementContext);

export const StateManagementContextProvider = ({ children }) => {
  const [state, setState] = useState("landing");
  const [dataLink, setDataLink] = useState("");
  const [analyticState, setAnalyticState] = useState("visitor");
  const [rangeState, setRangeState] = useState("week");
  const [totalDataPerPage, setTotalDataPerPage] = useState();
  const [file, setFile] = useState(null);
  const [bulkShortenerState, setBulkShortenerState] = useState("how to use");
  const [processing, setProcessing] = useState(true);

  const value = {
    state,
    setState,
    dataLink,
    setDataLink,
    analyticState,
    setAnalyticState,
    rangeState,
    setRangeState,
    totalDataPerPage,
    setTotalDataPerPage,
    file,
    setFile,
    bulkShortenerState,
    setBulkShortenerState,
    processing,
    setProcessing,
  };

  return (
    <StateManagementContext.Provider value={value}>
      {children}
    </StateManagementContext.Provider>
  );
};
