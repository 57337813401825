import { useBackdropContext } from "../../../../context/BackdropContext/BackdropContext";
import { useDrawerContext } from "../../../../context/DrawerContext/DrawerContext";

const StyledFooter = ({ children }) => {
  const { drawerOpened } = useDrawerContext();
  const { backdropActive } = useBackdropContext();

  return (
    <div
      className={`text-secondMineShaft bg-[#F2F2F2] lg:py-12 py-8 lg:px-20 px-6 absolute b-0 w-full z-[85] ${
        drawerOpened && "z-[91]"
      } ${backdropActive && "z-[88]"}`}
    >
      {children}
    </div>
  );
};

export default StyledFooter;
